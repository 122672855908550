import React, { useContext } from 'react'
import { SettingsContext } from '../../store'
import Icon from '../atmos/icon'

const Header = () => {
  const { setSettingsVisibility } = useContext(SettingsContext)

  return (
    <header className='p-4 border-b border-gray-200'>
      <div className='flex items-center'>
        <div
          className='mr-3 sm:hidden'
          onClick={() => setSettingsVisibility(false)}
        >
          <Icon name='back' size='18' />
        </div>

        <a href='/'>
          <img
            className='h-8 mr-3'
            src={require('../../images/brand.svg')}
            alt={process.env.REACT_APP_WEBSITE_NAME}
          />
        </a>

        <span className='px-2 text-xs text-gray-600 bg-gray-200 rounded'>
          {process.env.REACT_APP_WEBSITE_VERSION}
        </span>
      </div>
      <div className='mt-2'>
        This is not the official website of {process.env.REACT_APP_WEBSITE_NAME}.<br></br>
        Big thanks to <a href={process.env.REACT_APP_WEBSITE_REPOSITORY}>bourhaouta</a> for allowing us to publish this website.
      </div>
    </header>
  )
}
export default Header
